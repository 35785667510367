<div *ngIf="loading" class="krucial-loading-spinner-container">
    <mat-spinner class="spinner" [style.display]="loading ? 'block' : 'none'"></mat-spinner>
</div>

<div [hidden]="loading">
    <div class="krucial-table-container">
        <form [formGroup]="filterForm">
            <mat-form-field>
                <mat-label i18n>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="startDateFilter" placeholder="Start date" i18n-placeholder>
                    <input matEndDate formControlName="endDateFilter" placeholder="End date" i18n-placeholder>
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </form>

        <mat-table [dataSource]="tableDataSource" class="mat-elevation-z8" matSort data-cy="housekeeping-table">
            <ng-container matColumnDef="Recorded At">
                <mat-header-cell *matHeaderCellDef i18n>Recorded At</mat-header-cell>
                <mat-cell *matCellDef="let housekeeping">
                    <span class="mobile-label" i18n>Recorded At</span>
                    <span>{{housekeeping.recordedAt | date:'d/MM/yyyy - HH:mm:ss'}}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Battery Voltage">
                <mat-header-cell *matHeaderCellDef i18n>Battery Voltage</mat-header-cell>
                <mat-cell *matCellDef="let housekeeping">
                    <span class="mobile-label" i18n>Battery Voltage</span>
                    <span>{{housekeeping.batteryVoltage + 'V'}}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Charge Status">
                <mat-header-cell *matHeaderCellDef i18n>Charge Status</mat-header-cell>
                <mat-cell *matCellDef="let housekeeping">
                    <span class="mobile-label" i18n>Charge Status</span>
                    <span>{{getChargeStatus(housekeeping)}}</span>

                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [length]="tableDataSource.totalRecords()" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>
