import {AfterViewInit, Component, ViewChild} from "@angular/core";
import {
  DeploymentDto,
  DeploymentService, DeviceDataDto
} from "@r3-iot/api-sigma";
import {AlertBannerService} from "@r3-iot/common";
import {finalize, take} from "rxjs";
import {UntypedFormGroup} from "@angular/forms";
import { MatTable, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DevicesDataSource} from "./devices-data.source";
import {DeploymentManagementService} from "../../deployment-management.service";
import { DatePipe } from "@angular/common";

@UntilDestroy()
@Component({
  selector: 'app-devices-data',
  templateUrl: './devices-data.component.html',
  styleUrls: ['./devices-data.component.scss'],
  standalone: true,
  imports: [MatTableModule, MatPaginatorModule, DatePipe]
})

export class DevicesDataComponent implements AfterViewInit {
  @ViewChild('deviceDataTable') devicesTable : MatTable<DeviceDataDto>;
  @ViewChild('deviceDataPaginator', {static: true}) deviceDataPaginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  alertBannerDismiss = $localize`Dismiss`;
  alertBannerOk = $localize`OK`;
  data: any;
  form: UntypedFormGroup;
  deployment: DeploymentDto;
  saving = false;
  loading = true;
  deviceDataTableSource: DevicesDataSource;
  displayedDeviceDataColumns=['Message', 'DevEUI', 'Created At']


  constructor(private deploymentsService: DeploymentService,
              private deploymentManagementService: DeploymentManagementService,
              public alertBannerService: AlertBannerService,
              ) {
    this.deviceDataTableSource = new DevicesDataSource();
  }


  ngAfterViewInit(): void {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deployment: DeploymentDto) => {
        if(!deployment) return;

        this.deployment = deployment;
        this.loadDeviceData();
      }
    });

    this.deviceDataPaginator.page
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.loadDeviceData()
        });
  }

  loadDeviceData(): void {
    this.deviceDataTableSource.getPageData(
        this.deploymentsService.v1DeploymentNameDevicedataGet(this.deployment.name, null,
            null, this.deviceDataPaginator.pageSize, this.deviceDataPaginator.pageIndex,
            "created at", "DESC", 'response')
            .pipe(
                take(1),
                finalize(() => {
                  this.loading = false;
                }))
    );
  }
}
