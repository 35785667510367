import {mapToCanActivate, Routes} from "@angular/router";
import {AuthorizationGuard} from "../../../../shared/guards/auth.guard";
export const DEVICES_ROUTES: Routes = [
    { path: '', redirectTo: 'details', pathMatch: 'full'},
    { path: 'details', loadComponent: () => import('./devices-view/devices-view.component').then(mod => mod.DevicesViewComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'data', loadComponent: () => import('./devices-data/devices-data.component').then(mod => mod.DevicesDataComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
    { path: 'add-device', loadComponent: () => import('./devices-view/add-device-on-deployment/add-device-on-deployment.component')
            .then(mod => mod.AddDeviceOnDeploymentComponent),
        canActivate: mapToCanActivate([AuthorizationGuard])},
];
