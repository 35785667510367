import {mapToCanActivate, Routes} from "@angular/router";
import {AuthorizationGuard} from "../../../shared/guards/auth.guard";

export const NEW_DEVICE_PROFILE_ROUTES: Routes = [
  {path: '', redirectTo: 'manual-entry', pathMatch: 'full'},
  {path: 'manual-entry', loadComponent: () => import('../new-device-profile/manual-entry-device-profile/manual-entry-device-profile.component')
        .then(mod => mod.ManualEntryDeviceProfileComponent), canActivate: mapToCanActivate([AuthorizationGuard])},
  {path: 'upload-a-file', loadComponent: () => import('../new-device-profile/upload-device-profile/upload-device-profile.component')
        .then(mod => mod.UploadDeviceProfileComponent), canActivate: mapToCanActivate([AuthorizationGuard])},
];
