<nav fitInkBarToContent mat-tab-nav-bar #tabGroup mat-align-tabs="center" [tabPanel]="tabPanel">
    <ng-container *ngFor="let tab of tabs">
        <a mat-tab-link
           *userHasRole="tab.userHasRole"
           [active]="activeTab === tab" data-name="{{tab.displayName}}"
           [routerLink]="tab.route" i18n [attr.data-cy]="'k-cell-tab-' + tab.displayName.toLowerCase()">
            {{tab.displayName}}
        </a>
    </ng-container>
</nav>
<mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>
