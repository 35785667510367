<div class="parent-nav">
    <mat-card appearance="outlined" *ngIf="!loading">
        <div class="krucial-card">
            <button mat-icon-button (click)="goToDeployments()" data-cy="back-button">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <h1 class="center-title" i18n>
                {{deployment.name}}
            </h1>
            <div></div>
        </div>
    </mat-card>

    <nav mat-tab-nav-bar #tabGroup mat-align-tabs="center" [tabPanel]="tabPanel">
        <ng-container *ngFor="let tab of tabs">
            <a mat-tab-link
               *userHasRole="tab.userHasRole"
               [active]="activeTabParent === tab" data-name="{{tab.displayName}}"
               [routerLink]="tab.route" i18n [attr.data-cy]="'k-cell-tab-' + tab.displayName.toLowerCase()">
                <div class="icon-small">
                    <svg class="fill-white krucial-icon-small">
                        <use [attr.href]="tab.iconName"></use>
                    </svg>
                </div>

                {{tab.displayName}}
            </a>
        </ng-container>
    </nav>
</div>

<!-- Deployment Summary -->
<mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>

