import {Component, Host, OnInit} from '@angular/core';
import {DeploymentManagementService} from "./deployment-management.service";
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterModule, RouterOutlet} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {finalize, take} from "rxjs";
import {DeploymentDto} from "@r3-iot/api-sigma";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {NgForOf, NgIf, TitleCasePipe} from '@angular/common';
import {MatTabLink, MatTabNav, MatTabNavPanel} from "@angular/material/tabs";
import {NavItem, R3CommonModule} from "@r3-iot/common";
import {MatCard} from "@angular/material/card";
import {MatIconButton} from "@angular/material/button";

@UntilDestroy()
@Component({
    selector: 'app-deployment-management',
    templateUrl: './deployment-management.component.html',
    styleUrls: ['./deployment-management.component.scss'],
    // Define DeploymentManagementService as a provider so that child components can access it. This ensures the service
    // is disposed of when the app leaves the scope of CellDetailsComponent
    providers: [DeploymentManagementService],
    standalone: true,
  imports: [NgIf, MatProgressSpinnerModule, RouterOutlet, MatTabLink, MatTabNav, NgForOf, R3CommonModule, RouterLink,
    MatTabNavPanel, MatCard, MatIconButton]
})

export class DeploymentManagementComponent implements OnInit {

  loading: boolean = true;
  deployment: DeploymentDto;

  menuKCell = $localize`K-Cell`;
  menuDevices = $localize`Devices`;
  menuPlugins = $localize`Plugins`;

  tabs: NavItem[] = [
    {
      displayName: this.menuDevices,
      iconName: 'assets/icons/sprite.svg#r3-icon-sensors',
      route: `./devices`,
      userHasRole: '',
      children: [],
    },
    {
      displayName: this.menuKCell,
      iconName: 'assets/icons/sprite.svg#r3-icon-gateways',
      route: `./k-cell`,
      userHasRole: '',
      children: [],
    },
    {
      displayName: this.menuPlugins,
      iconName: 'assets/icons/sprite.svg#r3-icon-plugin',
      route: `./plugins`,
      userHasRole: '',
      children: [],
    }
  ]

  activeTabParent: NavItem;

  constructor(@Host() public deploymentService: DeploymentManagementService, private route: ActivatedRoute,
              private router: Router, private titleCasePipe: TitleCasePipe) {
  }


  ngOnInit() {
    // This is the top level component. On load, we grab the ID from the URL and load that Deployment
    // into the Service. Each child component subsequently refers to the service to find the current deployment-management.
    this.route.params.pipe(untilDestroyed(this)).subscribe({
      next: params => {
        this.deploymentService.getDeployment(params.name)
            .pipe(
                take(1),
                finalize(() => {
                  this.loading = false;
                })
            ).subscribe({
          next: (deployment: DeploymentDto) => {
            this.deployment = deployment;
          }
        });
      }
    });

    // with the tab structure we need to get the current viewed child component from the router url
    // this is in the place of the second last index of the array
    // we use this value to determine the active tab
    const routerSplit = this.router.url.split('/');
    const currentRoute: string = this.titleCasePipe.transform(routerSplit[routerSplit.length - 2]) as string;

    this.activeTabParent = this.tabs.find(x => x.displayName.toLowerCase() === currentRoute.toLowerCase()) as NavItem;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const routerSplit = this.router.url.split('/');
        let activeValue: string;

        // as some tabs have child tabs the position of the parent tab might be different in the array
        // if the parent tab has a child tab there will be an extra value appended to the end of the array
        // in  the case there is no child tab we need to move the index to the last element as opposed to the second last
        // to account for there being no extra value at the end to represent the child tab
        if (routerSplit[routerSplit.length - 2] === this.deployment.name) {
          activeValue = routerSplit[routerSplit.length - 1];
        }else{
          activeValue = routerSplit[routerSplit.length - 2];
        }

        const route: string = this.titleCasePipe.transform(activeValue) as string;

        this.activeTabParent = this.tabs.find(x => x.displayName.toLowerCase() === route.toLowerCase()) as NavItem;
      }
    });
  }

  goToDeployments(): void {
    this.router.navigate(['../../deployments'], { relativeTo: this.route });
  }
}
