<div *ngIf="loading" class="krucial-loading-spinner-container">
    <mat-spinner class="spinner" [style.display]="loading ? 'block' : 'none'"></mat-spinner>
</div>

<div *ngIf="!loading && deployment" class="k-cell-configuration-container">
    <h2 i18n>K-Cell Serial Number</h2>
    <mat-card appearance="outlined" class="k-cell-configuration-card">
        <div class="krucial-space-between-title-value-tall">
            <div i18n><b>Serial Number</b></div>
            <div i18n>{{ deployment.kCellSerialNumber }}</div>
        </div>
    </mat-card>

    <h2 i18n>Wi-Fi</h2>
    <mat-card appearance="outlined" class="k-cell-configuration-card">
        <div class="krucial-space-between-title-value-tall">
            <div i18n><b>Enable Wi-Fi</b></div>
            <button *ngIf="!enablingWifi" [disabled]="apiRequestPending" mat-stroked-button
                    (click)="enableWifi()"
                    data-cy="enable-wifi-button" i18n>Enable <mat-icon>wifi</mat-icon></button>
            <mat-spinner diameter="36" *ngIf="enablingWifi"></mat-spinner>
        </div>
        <div class="info-text">
            <div i18n>Send a message to the K-Cell to enable Wi-Fi.</div>
            <div i18n>A K-Cell requires an SSID and Password to be set before Wi-Fi can be enabled.</div>
        </div>
        <mat-divider></mat-divider>
        <div class="krucial-space-between-title-value-tall">
            <div i18n><b>Disable Wi-Fi</b></div>
            <button *ngIf="!disablingWifi" [disabled]="apiRequestPending" mat-stroked-button
                    (click)="disableWifi()"
                    data-cy="disable-wifi-button" i18n>Disable <mat-icon>wifi_off</mat-icon></button>
            <mat-spinner diameter="36" *ngIf="disablingWifi"></mat-spinner>
        </div>
        <div class="info-text">
            <div i18n>Send a message to the K-Cell to disable Wi-Fi.</div>
        </div>
        <mat-divider></mat-divider>
        <div class="krucial-space-between-title-value-tall">
            <div i18n><b>Set SSID & Password</b></div>
            <button *ngIf="!settingSsidAndPassword" [disabled]="apiRequestPending" mat-stroked-button
                    (click)="setSsidAndPassword()"
                    data-cy="set-ssid-and-password-button" i18n>Set <mat-icon>settings</mat-icon></button>
            <mat-spinner diameter="36" *ngIf="settingSsidAndPassword"></mat-spinner>
        </div>
        <div class="info-text">
            <div i18n>Send a new Wi-Fi SSID and Password to the K-Cell.</div>
        </div>
    </mat-card>

    <h2 i18n>Send Message Timings and Requests to K-Cell</h2>
    <mat-card appearance="outlined" class="k-cell-configuration-card">
        <div class="krucial-space-between-title-value-tall">
            <div i18n><b>Set Message Timings</b></div>
            <button [disabled]="apiRequestPending" mat-stroked-button
                    (click)="openViewConfigTimingsDialog()"
                    data-cy="send-timings-button" i18n>Set <mat-icon>settings</mat-icon></button>
            <mat-spinner diameter="36" *ngIf="settingConfigTimings"></mat-spinner>
        </div>
        <div class="info-text">
            <div i18n>Send new message timings to the K-Cell.</div>
        </div>

        <mat-divider></mat-divider>
        <div class="krucial-space-between-title-value-tall">
            <div i18n><b>Status and Reboot Request</b></div>
            <button [disabled]="apiRequestPending" mat-stroked-button
                    (click)="openSendRequestsDialog()"
                    data-cy="send-status-and-reboot-request-button" i18n>Set <mat-icon>settings</mat-icon></button>
            <mat-spinner diameter="36" *ngIf="settingSsidAndPassword"></mat-spinner>
        </div>
        <div class="info-text">
            <div i18n>Send status and reboot messages to the K-Cell.</div>
        </div>
    </mat-card>

</div>
