import {AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
  DeploymentDto,
  DeploymentService,
  DeviceService,
  QueueDto, RemoveDeviceFromDeploymentDto
} from "@r3-iot/api-sigma";
import { AlertBannerService, R3CommonModule } from "@r3-iot/common";
import {debounceTime, distinctUntilChanged, finalize, take} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import { MatTable, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import {MatSort, MatSortModule } from "@angular/material/sort";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DevicesViewSource} from "./devices-view.source";
import {
  DeleteAcknowledgeDialogComponent
} from "../../../../../shared/dialogs/delete-acknowledge-dialog/delete-acknowledge-dialog.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import {DeploymentManagementService} from "../../deployment-management.service";
import {
  AddDeviceToDeploymentDialogComponent
} from "../../../../devices/add-device-to-deployment-dialog-component/add-device-to-deployment-dialog.component";
import {
  AddDeviceOnDeploymentComponent
} from "./add-device-on-deployment/add-device-on-deployment.component";


@UntilDestroy()
@Component({
  selector: 'app-devices-view',
  templateUrl: './devices-view.component.html',
  styleUrls: ['./devices-view.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule,
    MatTableModule, MatPaginatorModule, R3CommonModule, FormsModule, ReactiveFormsModule, MatSort, MatSortModule],
  encapsulation: ViewEncapsulation.None
})

export class DevicesViewComponent implements OnInit, AfterViewInit {
  @ViewChild('devicesTable') devicesTable : MatTable<string>;
  @ViewChild('devicePaginator', { static: true }) devicePaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  alertBannerDismiss = $localize`Dismiss`;
  alertBannerOk = $localize`OK`;
  alertBannerDeviceRemovedAndDeleted = $localize`Device removed from deployment.`;
  alertBannerDeleteError = $localize`Error communicating with server when deleting device`;
  data: any;
  form: UntypedFormGroup;
  deployment: DeploymentDto;
  queue: QueueDto[];
  saving = false;
  loading = true;
  devicesViewDataSource: DevicesViewSource;
  displayedDeviceColumns=['DevEUI', 'Name',  'Operations']
  filterForm: UntypedFormGroup;

  // Used to convert the active sort column name to a key supported by the API
  sortKeys = {
    "Name": "name",
    "DevEUI": "deveui"
  };

  constructor(private router: Router, private dialog: MatDialog,
              private deploymentsService: DeploymentService,
              private deploymentManagementService: DeploymentManagementService,
              private deviceService: DeviceService,
              public alertBannerService: AlertBannerService, private route: ActivatedRoute) {
    this.devicesViewDataSource = new DevicesViewSource();
  }

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      deviceFilter: new UntypedFormControl(null)
    });

    this.filterForm.valueChanges
        .pipe(untilDestroyed(this),
            debounceTime(1000), distinctUntilChanged()).subscribe({
      next: () => {
        this.devicePaginator.pageIndex = 0;
        this.loadDevices();
      }
    });
  }

  ngAfterViewInit(): void {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deployment: DeploymentDto) => {
        if (!deployment) { return; }
        this.deployment = deployment;
        this.loadDevices();
      }
    })

    this.devicePaginator.page.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadDevices()
    });

    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.loadDevices();
      }
    })
  }

  loadDevices(): void {
    this.devicesViewDataSource.getPageData(
        this.deviceService.v1DeviceGet(
            this.filterForm.controls.deviceFilter.value,
            this.deployment.name,
            this.devicePaginator.pageSize, this.devicePaginator.pageIndex,
            this.sortKeys[this.sort.active], this.sort.direction, false, false, 'response')
            .pipe(take(1), finalize(() => {
              this.loading = false;
            }))
    );
  }


  deleteRow(event: { stopPropagation: () => void; }, deviceList: string[]): void {
    event.stopPropagation();

    const payload: RemoveDeviceFromDeploymentDto = {
      deviceDevEuis: deviceList
    };

    this.deploymentsService.v1DeploymentNameDeviceDelete(this.deployment.name, payload)
        .pipe(take(1)).subscribe({
      next: () => {
        this.alertBannerService.open(this.alertBannerDeviceRemovedAndDeleted, [this.alertBannerOk]);
        this.loadDevices();
      },
      error: () => {
        this.alertBannerService.open(this.alertBannerDeleteError, [this.alertBannerDismiss]);
      }
    });
  }

  goToAddDevices(){
    this.router.navigate([`../add-device`], {relativeTo: this.route });
  }

  openDeleteDeviceDialog(event: MouseEvent, deviceName: string, devEui: string): void {
    event.stopPropagation();
    const deviceList = [];
    deviceList.push(devEui);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "krucial-large-modal-container";
    dialogConfig.data = {
      title: $localize`Remove Device ${deviceName} from ${this.deployment.name}`,
      optionalMessage: $localize`This action can not be undone.`
    };
    this.dialog.open(DeleteAcknowledgeDialogComponent, dialogConfig).afterClosed()
        .pipe(take(1))
        .subscribe({
          next: response => {
            if (response === 'delete'){
              this.deleteRow(event, deviceList);
            }
          }
        });
  }

  goToDeploymentDevice(event: MouseEvent, devEui: string): void {
    event.stopPropagation();
    this.router.navigate([`/home/deployments/${this.deployment.name}/devices/${devEui}/details`]);
  }
}
