import {DeploymentManagementComponent} from "./deployment-management.component";
import {ModuleWithProviders, NgModule} from "@angular/core";
import {R3CommonModule, UserHasRoleDirective, UserIsRoleDirective} from "@r3-iot/common";
import {RouterModule} from "@angular/router";
import { KCellComponent } from './k-cell/k-cell.component';
import { DevicesComponent } from './devices/devices.component';
import { KCellStatusComponent } from './k-cell/k-cell-status/k-cell-status.component';
import { KCellHousekeepingComponent } from './k-cell/k-cell-housekeeping/k-cell-housekeeping.component';
import { KCellQueueComponent } from './k-cell/k-cell-queue/k-cell-queue.component';
import {ReactiveFormsModule} from "@angular/forms";
import { DevicesViewComponent } from './devices/devices-view/devices-view.component';
import { DevicesDataComponent } from './devices/devices-data/devices-data.component';
import {EditDeviceDialogComponent} from "./devices/devices-view/edit-device-dialog/edit-device-dialog.component";
import {DeploymentManagementService} from "./deployment-management.service";
import { KCellOperationsComponent } from './k-cell/k-cell-operations/k-cell-operations.component';
import { SelectReplacementGatewayDialogComponent } from './k-cell/k-cell-operations/select-replacement-gateway-dialog/select-replacement-gateway-dialog.component';
import { KCellConfigurationComponent } from './k-cell/k-cell-configuration/k-cell-configuration.component';
import { SetWifiCredentialsDialogComponent } from './k-cell/k-cell-configuration/set-wifi-credentials-dialog/set-wifi-credentials-dialog.component';
import {TimingsConfigDialogComponent} from './k-cell/k-cell-configuration/timings-config-dialog/timings-config-dialog.component';
import { SendRequestsDialogComponent } from './k-cell/k-cell-configuration/send-requests-dialog/send-requests-dialog.component'

@NgModule({
    imports: [
    RouterModule,
    R3CommonModule,
    ReactiveFormsModule,
    DeploymentManagementComponent,
    KCellComponent,
    DevicesComponent,
    KCellStatusComponent,
    KCellHousekeepingComponent,
    KCellQueueComponent,
    DevicesViewComponent,
    DevicesDataComponent,
    EditDeviceDialogComponent,
    KCellOperationsComponent,
    SelectReplacementGatewayDialogComponent,
    KCellConfigurationComponent,
    SetWifiCredentialsDialogComponent,
    TimingsConfigDialogComponent,
    SendRequestsDialogComponent
],
    providers: [DeploymentManagementService],
    exports: [
        DeploymentManagementComponent
    ]
})
export class DeploymentManagementModule {
    static forRoot(configuration: any): ModuleWithProviders<DeploymentManagementModule> {
        return {
            ngModule: DeploymentManagementModule,
            providers: [
                UserIsRoleDirective, {provide: 'RolesConfig', useValue: configuration.RolesConfig},
                UserHasRoleDirective, {provide: 'RolesConfig', useValue: configuration.RolesConfig},
                // Inject environment variable
                {
                    provide: 'Environment', // you can also use InjectionToken
                    useValue: configuration.environment
                }
            ]
        };
    }
}
