import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {
  DeploymentDto,
  DeploymentService,
  QueueDto
} from "@r3-iot/api-sigma";
import { R3CommonModule } from "@r3-iot/common";
import { MatTable, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DeploymentManagementService} from "../../deployment-management.service";
import { DatePipe } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import {KCellQueueSource} from "./k-cell-queue.source";
import {debounceTime, distinctUntilChanged, finalize, take} from "rxjs";
import {FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from "@angular/forms";

@UntilDestroy()
@Component({
  selector: 'app-k-cell-queue',
  templateUrl: './k-cell-queue.component.html',
  styleUrls: ['./k-cell-queue.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatPaginatorModule,
    DatePipe, R3CommonModule, FormsModule, ReactiveFormsModule, MatSortModule]
})

export class KCellQueueComponent implements OnInit, AfterViewInit {
  @ViewChild('queueTable') queueTable : MatTable<QueueDto>;
  @ViewChild('queuePaginator', {static: true}) queuePaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  alertBannerDismiss = $localize`Dismiss`;
  alertBannerOk = $localize`OK`;
  data: any;

  displayedQueueColumns=['Action Type', 'DevEUI', 'Status', 'Created At', 'Completed At']
  deployment: DeploymentDto;
  queue: QueueDto[];
  loading = true;
  queueDataSource: KCellQueueSource;
  filterForm: UntypedFormGroup;

  sortKeys = {
    "Created At": "createdat",
    "Completed At": "acknowledgedat",
  };

  constructor(private deploymentsService: DeploymentService,
              private deploymentManagementService: DeploymentManagementService) {
    this.queueDataSource = new KCellQueueSource();
  }

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      devEuiFilter: new UntypedFormControl(null)
    });

    this.filterForm.valueChanges
        .pipe(untilDestroyed(this),
            debounceTime(1000), distinctUntilChanged()).subscribe({
      next: () => {
        this.queuePaginator.pageIndex = 0;
        this.loadKCellQueueData();
      }
    });
  }

  ngAfterViewInit(): void {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deployment: DeploymentDto) => {
        if(!deployment) return;

        this.deployment = deployment;
        this.loadKCellQueueData();
      }
    });

    this.queuePaginator.page.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadKCellQueueData()
    });

    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.loadKCellQueueData();
      }
    });
  }

  loadKCellQueueData(): void {
    this.queueDataSource.getPageData(
        this.deploymentsService.v1DeploymentNameQueueDaysGet(this.deployment.name, 14,
            this.sortKeys[this.sort.active], this.sort.direction,
            this.filterForm.controls.devEuiFilter.value, this.queuePaginator.pageSize,
            this.queuePaginator.pageIndex, 'response')
            .pipe(take(1), finalize(() => {
              this.loading = false;
            }))
    );
  }
}
