import { Component } from '@angular/core';
import { NavItem, R3CommonModule } from "@r3-iot/common";
import {DeploymentDto} from "@r3-iot/api-sigma";
import {DeploymentManagementService} from "../deployment-management.service";
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import { TitleCasePipe, NgFor } from "@angular/common";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy()
@Component({
    selector: 'app-deployment-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, MatTabsModule, NgFor, R3CommonModule, RouterLink, RouterOutlet]
})
export class DevicesComponent {

  menuDevices = $localize`Details`;
  menuData = $localize`Data`;

  tabs: NavItem[] = [
    {
      displayName: this.menuDevices,
      iconName: '',
      route: `./details`,
      userHasRole: '',
      children: [],
    },
    {
      displayName: this.menuData,
      iconName: '',
      route: `./data`,
      userHasRole: '',
      children: [],
    }
  ]

  activeTab: NavItem;
  loading: boolean = true;
  deployment: DeploymentDto;

  constructor(private deploymentManagementService: DeploymentManagementService, private router: Router,
              private titleCasePipe: TitleCasePipe) {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deploymentDto: DeploymentDto) => {
        this.deployment = deploymentDto;
      }
    })

    const currentRoute: string = this.titleCasePipe.transform(this.router.url.split('/').pop()) as string;
    this.activeTab = this.tabs.find(x => x.displayName.toLowerCase() === currentRoute.toLowerCase()) as NavItem;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const route: string = this.titleCasePipe.transform(this.router.url.split('/').pop()) as string;
        this.activeTab = this.tabs.find(x => x.displayName.toLowerCase() === route.toLowerCase()) as NavItem;
      }
    });
  }
}

