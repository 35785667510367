import { Component } from '@angular/core';
import {DeploymentDto, KCellDto, KCellService} from "@r3-iot/api-sigma";
import {take} from "rxjs";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DeploymentManagementService} from "../../deployment-management.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {
  SelectReplacementGatewayDialogComponent
} from "./select-replacement-gateway-dialog/select-replacement-gateway-dialog.component";
import {AlertBannerService} from "@r3-iot/common";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy()
@Component({
  selector: 'app-k-cell-operations',
  templateUrl: './k-cell-operations.component.html',
  styleUrls: ['./k-cell-operations.component.scss'],
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatIconModule]
})
export class KCellOperationsComponent {

  deployment: DeploymentDto;
  replaceGatewayNoGateways: string = $localize`Replace cannot be performed as there are no available K-Cells in your organisation.`;
  replaceGatewaySuccess: string = $localize`K-Cell was replaced successfully, but will take time until new data is available.`;
  errorMessage500: string = $localize`Error communicating with server. Please wait a moment then try again`;
  alertBannerOk: string = $localize`OK`;

  constructor(private kCellApiService: KCellService, private deploymentManagementService: DeploymentManagementService,
              private dialog: MatDialog, private alertBannerService: AlertBannerService) {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deployment: DeploymentDto) => {
        this.deployment = deployment;
      }
    })
  }

  openReplaceGatewayModal(): void {
    // we are passing nothing into the filter parameter as we want to get all kCells
    this.kCellApiService.v1KcellGet("", 0, 10, "created at", "DESC", false, true).pipe(take(1)).subscribe({
      next: (gateways: KCellDto[]) => {
        if (gateways.length === 0) {
          this.alertBannerService.open(this.replaceGatewayNoGateways, [this.alertBannerOk]);
          this.deploymentManagementService.refresh();
          return;
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'krucial-medium-modal-container';

        dialogConfig.data = {
          deployment: this.deployment,
          currentGatewaySerialNumber: this.deployment.kCellSerialNumber,
          gateways: gateways
        }
        this.dialog.open(SelectReplacementGatewayDialogComponent, dialogConfig).afterClosed()
            .pipe(take(1)).subscribe({
          next: (result: any) => {
            if (!result) {
              return;
            }

            if (result === 'success') {
              this.alertBannerService.open(this.replaceGatewaySuccess, [this.alertBannerOk]);
              this.deploymentManagementService.refresh();
              return;
            }
            if ('status' in result) {
              // The endpoint throws back many error codes, but not of them are useful to the average user.
              // They should contact support if this operation fails.
              this.alertBannerService.open(this.errorMessage500, [this.alertBannerOk]);
              return;
            }
          }
        });
      }
    })
  }
}
