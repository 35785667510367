<div *ngIf="loading" class="krucial-loading-spinner-container">
    <mat-spinner class="spinner" [style.display]="loading ? 'block' : 'none'"></mat-spinner>
</div>

<div id="deployment-device-send-data-container" *ngIf="!loading">
    <!-- Landing page -->
    <ng-container *ngIf="!showSendEncodedPage && !showSendNonEncodedPage">
        <mat-card appearance="outlined" class="deployment-device-send-data-card">
            <div class="krucial-space-between-title-value">
                <div i18n><b>Send Encoded Data</b></div>
                <div>
                    <button mat-stroked-button (click)="showSendEncodedPage = true"
                            data-cy="go-to-send-encoded-data-button" i18n>Start</button>
                </div>
            </div>
            <div i18n class="info-text">Send pre-encoded data to the LoraWan device.</div>
            <mat-divider></mat-divider>
            <div class="krucial-space-between-title-value">
                <div i18n><b>Send Non-encoded Data</b></div>
                <div>
                    <button mat-stroked-button (click)="showSendNonEncodedPage = true"
                            data-cy="go-to-send-non-encoded-data-button" i18n>Start</button>
                </div>
            </div>
            <div i18n class="info-text">Send non-encoded data to the LoraWan device. Your data will be encoded using the
            codec specified by the device profile.</div>
        </mat-card>
    </ng-container>
    <ng-container *ngIf="showSendNonEncodedPage || showSendEncodedPage">
        <!-- Send encoded data page -->
        <ng-container *ngIf="showSendEncodedPage && !showSendNonEncodedPage">
            <div class="send-data-page-title">
                <button mat-icon-button data-cy="deployment-device-encoded-page-back-arrow" (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <h1 i18n>Send Encoded Data</h1>
                <div class="button-spinner-container">
                    <button mat-raised-button color="primary" i18n *ngIf="!saving" [disabled]="sendEncodedDataForm.invalid"
                            (click)="sendEncodedData()">Send</button>
                    <mat-spinner *ngIf="saving" diameter="25"></mat-spinner>
                </div>

            </div>
            <form [formGroup]="sendEncodedDataForm" class="w-100">
                <mat-card appearance="outlined">
                    <div>
                        <mat-form-field>
                            <mat-label i18n>Port Number</mat-label>
                            <input matInput type="number" formControlName="portNumber" placeholder="0" i18n-placeholder
                            data-cy="port-number-input">
                        </mat-form-field>
                    </div>
                </mat-card>
                <br>
                <mat-card appearance="outlined">
                    <div>
                        <mat-form-field>
                            <mat-label i18n>Data</mat-label>
                            <textarea matInput type="text" formControlName="data" placeholder="Data" i18n-placeholder
                                      data-cy="data-input"></textarea>
                        </mat-form-field>
                    </div>
                </mat-card>
            </form>
        </ng-container>
        <!-- Send non-encoded data page -->
        <ng-container *ngIf="!showSendEncodedPage && showSendNonEncodedPage">
            <div class="send-data-page-title">
                <button mat-icon-button data-cy="deployment-device-encoded-page-back-arrow" (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <h1 i18n>Send Non-encoded Data</h1>
                <div class="button-spinner-container">
                    <button mat-raised-button color="primary" *ngIf="!saving && keyValuePairControls.length > 0" [disabled]="sendNonEncodedDataForm.invalid" i18n
                            (click)="sendNonEncodedData()">Send</button>
                    <mat-spinner diameter="24" *ngIf="saving"></mat-spinner>
                </div>
            </div>
            <form [formGroup]="sendNonEncodedDataForm" class="w-100">
                <div class="add-button-container">
                    <button mat-stroked-button (click)="addControlPair(ValueType.Text)" i18n>
                        <mat-icon>add_circle_outline</mat-icon>
                        Text
                    </button>
                    <button mat-stroked-button (click)="addControlPair(ValueType.Number)" i18n>
                        <mat-icon>add_circle_outline</mat-icon>
                        Number
                    </button>
                    <button mat-stroked-button (click)="addControlPair(ValueType.Boolean)" i18n>
                        <mat-icon>add_circle_outline</mat-icon>
                        Boolean
                    </button>
                </div>
                <mat-card appearance="outlined" *ngIf="keyValuePairControls.length > 0">
                    <div *ngFor="let keyValuePairControlGroup of keyValuePairControls" class="key-value-pair-container">
                        <div class="key-value-pair-control-container">
                            <div>
                                <mat-form-field class="density-1">
                                    <mat-label i18n>Key</mat-label>
                                    <input matInput type="text" [formControlName]="'key-' + keyValuePairControlGroup.pairNumber" placeholder="Key" i18n-placeholder
                                           [attr.data-cy]="'key-' + keyValuePairControlGroup.pairNumber + '-control'">
                                </mat-form-field>
                            </div>
                            <!-- Input type is dependant on the ValueType -->
                            <div *ngIf="keyValuePairControlGroup.valueType === ValueType.Text">
                                <mat-form-field class="density-1">
                                    <mat-label i18n>Value</mat-label>
                                    <input matInput type="text" [formControlName]="'value-' + keyValuePairControlGroup.pairNumber" placeholder="Value" i18n-placeholder
                                           [attr.data-cy]="'value-' + keyValuePairControlGroup.pairNumber + '-control'">
                                </mat-form-field>
                            </div>
                            <div *ngIf="keyValuePairControlGroup.valueType === ValueType.Number">
                                <mat-form-field class="density-1">
                                    <mat-label i18n>Value</mat-label>
                                    <input matInput type="number" [formControlName]="'value-' + keyValuePairControlGroup.pairNumber" placeholder="Value" i18n-placeholder
                                           [attr.data-cy]="'value-' + keyValuePairControlGroup.pairNumber + '-control'">
                                </mat-form-field>
                            </div>
                            <div *ngIf="keyValuePairControlGroup.valueType === ValueType.Boolean">
                                <mat-form-field class="density-1">
                                    <mat-label i18n>Value</mat-label>
                                    <mat-select  [formControlName]="'value-' + keyValuePairControlGroup.pairNumber"
                                                 [attr.data-cy]="'value-' + keyValuePairControlGroup.pairNumber + '-control'">
                                        <mat-option [value]="false"
                                                    data-cy="boolean-value-select-option-false">false</mat-option>
                                        <mat-option [value]="true"
                                                    data-cy="boolean-value-select-option-true">true</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div>
                            <button mat-icon-button (click)="deleteControlPair(keyValuePairControlGroup.pairNumber)"><mat-icon>delete</mat-icon></button>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </mat-card>
            </form>
        </ng-container>
    </ng-container>
</div>
