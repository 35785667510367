import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {CellHousekeepingDto, DeploymentDto, KCellService} from "@r3-iot/api-sigma";
import {debounceTime, distinctUntilChanged, finalize, take} from "rxjs";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import {KCellHousekeepingDataSource} from "./k-cell-housekeeping-data.source";
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DeploymentManagementService} from "../../deployment-management.service";
import { MatTableModule } from "@angular/material/table";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgIf, DatePipe } from "@angular/common";

@UntilDestroy()
@Component({
  selector: 'app-k-cell-housekeeping',
  templateUrl: './k-cell-housekeeping.component.html',
  styleUrls: ['./k-cell-housekeeping.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, ReactiveFormsModule, MatFormFieldModule,
    MatDatepickerModule, MatTableModule, MatSortModule, MatPaginatorModule, DatePipe, NgIf]
})

export class KCellHousekeepingComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  deployment: DeploymentDto;

  displayedColumns: string[] = ['Recorded At', 'Battery Voltage', 'Charge Status'];
  tableDataSource: KCellHousekeepingDataSource;
  filterForm: UntypedFormGroup;
  loading = true;
  acChargingText: string = $localize`Charging (AC)`;
  dcChargingText: string = $localize`Charging (DC)`;
  notChargingText: string = $localize`Not charging`;

  constructor(private kCellApiService: KCellService, private deploymentManagementService: DeploymentManagementService) {
    this.tableDataSource = new KCellHousekeepingDataSource();
  }

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      startDateFilter: new UntypedFormControl(null),
      endDateFilter: new UntypedFormControl(null)
    });

    this.filterForm.valueChanges
        .pipe(
            untilDestroyed(this),
            debounceTime(1000),
            distinctUntilChanged()
        ).subscribe({
      next: () => {
        this.paginator.pageIndex = 0;
        this.loadCellHousekeepingPage();
      }
    });

    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deploymentDto: DeploymentDto) => {
        this.deployment = deploymentDto;
        this.loadCellHousekeepingPage();
      }
    })
  }

  ngAfterViewInit(): void {
    this.paginator.page
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.loadCellHousekeepingPage();
        });
  }

  loadCellHousekeepingPage(): void {
    this.tableDataSource.getPageData(
        this.kCellApiService.v1KcellSerialNumberStatusBatteryGet(this.deployment.kCellSerialNumber,
            this.filterForm.controls.startDateFilter?.value?.toISOString(),
            this.filterForm.controls.endDateFilter?.value?.add(1, 'days').toISOString(), this.paginator.pageSize,
            this.paginator.pageIndex, null, null, "response")
            .pipe(take(1),
                finalize(() => {
                  this.loading = false;
                })
            )
    );
  }

  getChargeStatus(cellHousekeeping: CellHousekeepingDto): string {
    let chargeStatus: string = "";
    if (cellHousekeeping.acCharging)
      chargeStatus = this.acChargingText;
    else if (cellHousekeeping.dcCharging)
      chargeStatus = this.dcChargingText;
    else if (cellHousekeeping.notCharging)
      chargeStatus = this.notChargingText;
    return chargeStatus;
  }
}
