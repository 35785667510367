import { Component, OnInit } from '@angular/core';
import {DeviceDto, DeviceProfileService, DeviceProfileDto} from "@r3-iot/api-sigma";
import {DeploymentDeviceService} from "../deployment-device.service";
import {take} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EditDeviceDialogComponent} from "../../edit-device-dialog/edit-device-dialog.component";
import {AlertBannerService, R3CommonModule} from '@r3-iot/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-device-details',
    templateUrl: './device-details.component.html',
    styleUrls: ['./device-details.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, MatCardModule, MatDividerModule, MatButtonModule, MatIconModule, R3CommonModule]
})
export class DeviceDetailsComponent implements OnInit {

  device: DeviceDto;
  loading: boolean = true;
  deviceUpdatedSuccessfully: string = $localize`Device Profile successfully updated`;
  alertBannerDismiss = $localize`Dismiss`;
  alertBannerOk = $localize`OK`;

  constructor(private deploymentDeviceService: DeploymentDeviceService, private deviceProfileService: DeviceProfileService,
              private dialog: MatDialog, private alertBannerService: AlertBannerService) { }

  ngOnInit(): void {
    this.deploymentDeviceService.device$.pipe(untilDestroyed(this)).subscribe({
      next: (device: DeviceDto) => {
        this.device = device;
        this.loading = false;
      }
    })
  }

  openEditDeviceProfileDialog(event): void {
    event.stopPropagation();
      this.deviceProfileService.v1DeviceprofileNameGet(this.device.deviceProfileName).pipe(take(1)).subscribe({
        next: (deviceProfile: DeviceProfileDto) => {
          this.deviceProfileService.v1DeviceprofileGet().pipe(take(1)).subscribe({
            next: (deviceProfileList: DeviceProfileDto[]) => {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'krucial-large-modal-container';
              dialogConfig.data = {
                device: this.device,
                deviceProfile: deviceProfile,
                deviceProfileList: deviceProfileList
              };
              this.dialog.open(EditDeviceDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe({
                next: res => {
                  if (res) {
                    if (res.successfulCreate){
                      this.alertBannerService.open(this.deviceUpdatedSuccessfully, [this.alertBannerOk])
                      this.deploymentDeviceService.refresh();
                    }else{
                      this.alertBannerService.open($localize`${res.data.detail}`, [this.alertBannerDismiss])
                    }
                  }
                }
              });
            }
          });
        }
    });
  }
}
